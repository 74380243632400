import { useModal } from "contexts/Modal/ModalContext";
import Win from "components/Widgets/Modals/Win";
import Lose from "components/Widgets/Modals/Lose";
import Finish from "components/Widgets/Modals/Finish";

const ModalManager = () => {
  const { modals } = useModal();
  return (
    <>
      {modals?.win && <Win {...modals.win} />}
      {modals?.lose && <Lose {...modals.lose} />}
      {modals?.finish && <Finish {...modals.finish} />}
    </>
  );
};

export default ModalManager;
