import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useGames } from "contexts/Game/GameContext";
import { useModal } from "contexts/Modal/ModalContext";
import Button from "components/Shared/Button";
import "./PlayBonusButton.scss";
import axios from "axios";

const PlayBonusButton = ({ modalKeyToClose, gameId }) => {
  const { t } = useTranslation();
  const { handleResetGameSync, handleOpenGameSync } = useGames();
  const { closeModalSync } = useModal();

  const handlePlayBonus = useCallback(async () => {
    try {
      const response = await axios.post(
        "https://stagingapi.pokerplaza.com/wos/initialization",
        {
          action: "play",
          gameId,
        },
        {
          headers: {
            "x-access-token": localStorage.getItem("token"),
          },
        }
      );
      await handleResetGameSync();
      await closeModalSync(modalKeyToClose);
      await handleOpenGameSync(response?.data?.result?.url);
    } catch (error) {
      console.log(error);
    }
  }, [modalKeyToClose, gameId, handleResetGameSync, closeModalSync, handleOpenGameSync]);

  return (
    <Button shadow onClick={handlePlayBonus} buttonStyle="primary">
      <div className="play-bonus-button__texts-image-wrapper">
        <div className="play-bonus-button__texts-wrapper">
          <span>{t("playBonusTextFirstPart")}</span>
          <span>{t("playBonusTextSecondPart")}</span>
        </div>
        <div className="play-bonus-button__image" />
      </div>
    </Button>
  );
};

export default PlayBonusButton;
