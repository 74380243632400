import GameHeader from "./GameHeader";
import GameHint from "./GameHint";
import GameLayout from "./GameLayout/GameLayout";
import GameIframe from "./GameIframe/GameIframe";
import { useGames } from "contexts/Game/GameContext";
import "./Game.scss";

const Game = ({ onLogout }) => {
  const { gameUrl } = useGames();
  return (
    <div className="game">
      <GameHeader onLogout={onLogout} />
      {gameUrl ? (
        <div className="game__game-iframe-wrapper">
          <GameIframe />
        </div>
      ) : <>
      <div className="game__game-hint-wrapper">
        <GameHint />
      </div>
      <div className="game__game-layout-wrapper">
        <GameLayout />
      </div>
    </>}
    </div>
  );
};

export default Game;
