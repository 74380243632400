import { memo } from "react";
import { classNames } from "utils/common";
import "./Button.scss";

const Button = (props) => {
  const { children, buttonStyle, shadow, size, ...others } = props;
  return (
    <button
      {...others}
      className={classNames("button", {
        "button--shadow": !!shadow,
        [`button--${buttonStyle}`]: !!buttonStyle,
        [`button--${size}`]: !!size,
      })}
    >
      {children}
    </button>
  );
};

export default memo(Button);
