import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGames } from "../../../../contexts/Game/GameContext";
import { formatCurrency } from "utils/formatCurrency";
import Button from "components/Shared/Button";
import BetInput from "components/Shared/BetInput";
import "./GameBet.scss";
import axios from "axios";

const GameBet = () => {
  const { t } = useTranslation();
  const { games, gameSlotWinningIndex, handleStartRollingTogether, showBonusLevel, isRollingAllSlots, bonusPrices,  bonusPlayWinnigIndex, handleStartRollingTogetherSync, handleCashoutSync, handleOpenGameSync, handleResetGameSync, handleProvidesRollingSync } = useGames();

  const amount = useMemo(() => bonusPrices[bonusPlayWinnigIndex], [bonusPlayWinnigIndex]);

  const handleRoll = useCallback(async () => {
    if (showBonusLevel) {
      await handleCashoutSync();
      await handleResetGameSync();
      await new Promise((resolve) => setTimeout(resolve, 0));
      await handleStartRollingTogetherSync();
    } else {
      handleStartRollingTogether()
    }
  }, [showBonusLevel, handleResetGameSync, handleCashoutSync, handleProvidesRollingSync, handleStartRollingTogether, handleStartRollingTogetherSync]);

  const handlePlayBonus = useCallback(async () => {
    try {
      const response = await axios.post(
        "https://stagingapi.pokerplaza.com/wos/initialization",
        {
          action: "play",
          gameId: games[gameSlotWinningIndex].id,
        },
        {
          headers: {
            "x-access-token": localStorage.getItem("token"),
          },
        }
      );
      await handleResetGameSync();
      await handleOpenGameSync(response?.data?.result?.url);
    } catch (error) {
      console.log(error);
    }
  }, [games, gameSlotWinningIndex, handleResetGameSync, handleOpenGameSync]);

  return (
    <div className="game-bet">
      <div className="game-bet__bet-input-wrapper">
        <BetInput />
      </div>
      <div className="game-bet__buttons-wrapper">
        <div className="game-bet__button-wrapper">
          <Button
            disabled={!showBonusLevel}
            onClick={handlePlayBonus}
            buttonStyle="primary"
          >
            {t(showBonusLevel ? "playBonusLevel" : "playBonus", { amount: formatCurrency(amount, "USD") })}
          </Button>
        </div>
        <div className="game-bet__button-wrapper">
          <Button
            disabled={isRollingAllSlots}
            onClick={handleRoll}
            buttonStyle="secondary"
          >
            {t("roll")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GameBet;
