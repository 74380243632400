import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from "react";
import { isNumber } from "utils/common";
import {
  generateBonusPriceArray,
  generateDynamicBonusPrices,
  generateImageArray,
} from "utils/game";
import { useSynchronousDispatch } from "hooks/useSynchronousDispatch";

const GameContext = createContext();

const GAME_IDS = [1460,23343,22116,22138,22124,22127,22131,22136];
const DURATION_PROVIDERS = 1.7;
const DURATION_PRICES = 2.3;
const DEFAULT_ROLL_PRICE = 20;
const IMAGES_LENGTH = DURATION_PROVIDERS * 10;
const BONUS_PRICES_LENGTH = DURATION_PRICES * 10;

const initialState = {
  balance: 10000,
  durationProviders: DURATION_PROVIDERS,
  durationPrices: DURATION_PRICES,
  isReseted: false,
  isShowGame: false,
  gameUrl: null,
  isProvidersRolling: false,
  isProvidersRollingForButtonState: false,
  isBonusPricesRolling: false,
  isBonusPricesRollingForButtonState: false,
  step: 5,
  rollPrice: DEFAULT_ROLL_PRICE,
  gameSlotWinningIndex: Math.floor(Math.random() * IMAGES_LENGTH),
  bonusPlayWinnigIndex: Math.floor(Math.random() * BONUS_PRICES_LENGTH),
  games: generateImageArray(GAME_IDS, IMAGES_LENGTH),
  bonusPrices: generateBonusPriceArray(BONUS_PRICES_LENGTH, DEFAULT_ROLL_PRICE),
  showBonusLevel: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'setIsProvidersRollingForButtonState': {
      return {
        ...state,
        isProvidersRollingForButtonState: action.payload,
      }
    }
    case 'setIsBonusPricesRollingForButtonState': {
      return {
        ...state,
        isBonusPricesRollingForButtonState: action.payload,
      }
    }
    case 'startRollingTogether': {
      return {
        ...state,
        showBonusLevel: true,
        isProvidersRollingForButtonState: true,
        isBonusPricesRollingForButtonState: true,
        isProvidersRolling: true,
        isBonusPricesRolling: true,
        isReseted: false,
        balance:
          state.balance -
          (isNumber(action?.payload) ? action?.payload : state.rollPrice),
      };
    }
    case "startProvidersRolling": {
      return {
        ...state,
        isProvidersRolling: true,
        isReseted: false,
        balance:
          state.balance -
          (isNumber(action?.payload) ? action?.payload : state.rollPrice),
      };
    }
    case "startBonusPricesRolling": {
      return {
        ...state,
        isBonusPricesRolling: true,
      };
    }
    case "increaseRollPrice": {
      return {
        ...state,
        rollPrice: state.rollPrice + state.step,
        bonusPrices: generateDynamicBonusPrices(
          BONUS_PRICES_LENGTH,
          state.rollPrice + state.step,
          state.step
        ),
      };
    }
    case "decreaseRollPrice": {
      const newRollPrice =
        state.rollPrice <= state.step
          ? state.step
          : state.rollPrice - state.step;
      return {
        ...state,
        bonusPrices: generateDynamicBonusPrices(
          BONUS_PRICES_LENGTH,
          newRollPrice,
          state.step
        ),
        rollPrice: newRollPrice,
      };
    }
    case "cashout": {
      return {
        ...state,
        balance:
          state.balance +
          (isNumber(action?.payload)
            ? action.payload
            : state.bonusPrices[state.bonusPlayWinnigIndex]),
      };
    }
    case "openGame": {
      return {
        ...state,
        isShowGame: true,
        gameUrl: action.payload,
      };
    }
    case "closeGame": {
      return {
        ...state,
        isShowGame: false,
        gameUrl: null,
      };
    }
    case "resetGame": {
      return {
        ...initialState,
        balance: state.balance,
        rollPrice: state.rollPrice,
        isReseted: true,
        gameSlotWinningIndex: Math.floor(Math.random() * IMAGES_LENGTH),
        bonusPlayWinnigIndex: Math.floor(Math.random() * BONUS_PRICES_LENGTH),
        bonusPrices: generateBonusPriceArray(
          BONUS_PRICES_LENGTH,
          state.rollPrice
        ),
      };
    }
    case "logoutReset": {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

const GameProvider = ({ children }) => {
  const [
    {
      isProvidersRolling,
      isBonusPricesRolling,
      isProvidersRollingForButtonState,
      isBonusPricesRollingForButtonState,
      showBonusLevel,
      step,
      rollPrice,
      gameSlotWinningIndex,
      bonusPlayWinnigIndex,
      bonusPrices,
      games,
      balance,
      isReseted,
      isShowGame,
      durationProviders,
      durationPrices,
      gameUrl,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const gameSynchronousDispatch = useSynchronousDispatch(dispatch);

  const handleSetIsProvidersRollingForButtonState = useCallback((payload) => {
    dispatch({ type: 'setIsProvidersRollingForButtonState', payload });
  }, [dispatch]);

  const handleSetIsBonusPricesRolligForButtonState = useCallback((payload) => {
    dispatch({ type: 'setIsBonusPricesRollingForButtonState', payload });
  }, [dispatch]);

  const handleStartRollingTogether = useCallback((payload) => {
    dispatch({ type: 'startRollingTogether', payload });
  }, [dispatch]);

  const handleStartRollingTogetherSync = useCallback((payload) => {
    gameSynchronousDispatch({ type: 'startRollingTogether', payload });
  }, [gameSynchronousDispatch]);

  const handleProvidesRolling = useCallback(
    (payload) => dispatch({ type: "startProvidersRolling", payload }),
    [dispatch]
  );

  const handleProvidesRollingSync = useCallback(
    (payload) =>
      gameSynchronousDispatch({ type: "startProvidersRolling", payload }),
    [gameSynchronousDispatch]
  );

  const handleBonusPricesRolling = useCallback(
    () => dispatch({ type: "startBonusPricesRolling" }),
    [dispatch]
  );

  const handleIncreaseRollPrice = useCallback(
    () => dispatch({ type: "increaseRollPrice" }),
    [dispatch]
  );

  const handleDecreaseRollPrice = useCallback(
    () => dispatch({ type: "decreaseRollPrice" }),
    [dispatch]
  );

  const handleLogoutReset = useCallback(
    () => dispatch({ type: "logoutReset" }),
    [dispatch]
  );

  const handleResetGame = useCallback(
    () => dispatch({ type: "resetGame" }),
    [dispatch]
  );

  const handleResetGameSync = useCallback(
    () => gameSynchronousDispatch({ type: "resetGame" }),
    [gameSynchronousDispatch]
  );

  const handleCashout = useCallback(
    (payload) => dispatch({ type: "cashout", payload }),
    [dispatch]
  );

  const handleCashoutSync = useCallback(
    (payload) => gameSynchronousDispatch({ type: "cashout", payload }),
    [gameSynchronousDispatch]
  );

  const handleOpenGame = useCallback(
    () => dispatch({ type: "openGame" }),
    [dispatch]
  );

  const handleOpenGameSync = useCallback(
    (payload) => gameSynchronousDispatch({ type: "openGame", payload }),
    [gameSynchronousDispatch]
  );

  const handleCloseGame = useCallback(
    () => dispatch({ type: "closeGame" }),
    [dispatch]
  );

  const handleCloseGameSync = useCallback(
    () => gameSynchronousDispatch({ type: "closeGame" }),
    [gameSynchronousDispatch]
  );

  const values = useMemo(() => {
    return {
      isProvidersRolling,
      isBonusPricesRolling,
      isProvidersRollingForButtonState,
      isBonusPricesRollingForButtonState,
      step,
      rollPrice,
      gameSlotWinningIndex,
      bonusPlayWinnigIndex,
      bonusPrices,
      games,
      balance,
      isReseted,
      isShowGame,
      durationProviders,
      durationPrices,
      gameUrl,
      isRollingAllSlots: DURATION_PROVIDERS >= DURATION_PRICES ? isProvidersRollingForButtonState : isBonusPricesRollingForButtonState,
      showBonusLevel: showBonusLevel && !(DURATION_PROVIDERS >= DURATION_PRICES ? isProvidersRollingForButtonState : isBonusPricesRollingForButtonState),
      handleOpenGame,
      handleOpenGameSync,
      handleCloseGame,
      handleCloseGameSync,
      handleCashout,
      handleCashoutSync,
      handleProvidesRollingSync,
      handleResetGameSync,
      handleResetGame,
      handleProvidesRolling,
      handleBonusPricesRolling,
      handleStartRollingTogether,
      handleStartRollingTogetherSync,
      handleSetIsProvidersRollingForButtonState,
      handleSetIsBonusPricesRolligForButtonState,
      handleIncreaseRollPrice,
      handleDecreaseRollPrice,
      handleLogoutReset,
    };
  }, [
    isProvidersRolling,
    isBonusPricesRolling,
    isProvidersRollingForButtonState,
    isBonusPricesRollingForButtonState,
    step,
    rollPrice,
    gameSlotWinningIndex,
    bonusPlayWinnigIndex,
    bonusPrices,
    games,
    balance,
    isReseted,
    isShowGame,
    durationProviders,
    durationPrices,
    gameUrl,
    handleOpenGame,
    handleOpenGameSync,
    handleCloseGame,
    handleCloseGameSync,
    handleCashout,
    handleCashoutSync,
    handleProvidesRollingSync,
    handleResetGameSync,
    handleResetGame,
    handleProvidesRolling,
    handleBonusPricesRolling,
    handleStartRollingTogether,
    handleStartRollingTogetherSync,
    handleSetIsProvidersRollingForButtonState,
    handleSetIsBonusPricesRolligForButtonState,
    handleIncreaseRollPrice,
    handleDecreaseRollPrice,
    handleLogoutReset,
  ]);

  return <GameContext.Provider value={values}>{children}</GameContext.Provider>;
};

const useGames = () => useContext(GameContext);

export { GameProvider, useGames };
