import { useCallback } from "react";
import ModalTemplate from "components/Shared/ModalTemplate";
import Ticket from "components/Shared/Ticket";
import PlayBonusButton from "components/Shared/PlayBonusButton";
import CustomButton from "components/Shared/CustomButton";
import { formatCurrency } from "utils/formatCurrency";
import { useGames } from "contexts/Game/GameContext";
import { useModal } from "contexts/Modal/ModalContext";
import "./Win.scss";

const Win = ({ data }) => {
  const { handleCashoutSync, handleResetGameSync } = useGames();
  const { closeModalSync } = useModal();

  const onCashout = useCallback(async () => {
    await handleCashoutSync();
    await handleResetGameSync();
    await new Promise((resolve) => setTimeout(resolve, 500));
    await closeModalSync('win');
  }, [handleResetGameSync, closeModalSync, handleCashoutSync]);

  return (
    <div className="win">
      <ModalTemplate
        descriptionParams={{ amount: formatCurrency(data?.amount, "USD") }}
        modalKey="winModal"
      >
        <div className="win__inner">
          <div className="win__ticket-thubnail-wrapper">
            <div className="win__thubnail-wrapper">{data?.thumbnail}</div>
            <div className="win__ticket-wrapper">
              <Ticket amount={data?.amount} position="end" />
            </div>
          </div>
          <div className="win__buttons-wrapper">
            <div className="win__play-bonus-button-wrapper">
              <PlayBonusButton gameId={data?.gameId} modalKeyToClose="win" />
            </div>
            <div className="win__custom-button-wrapper">
              <CustomButton textKey="winModal" onClick={onCashout} />
            </div>
          </div>
        </div>
      </ModalTemplate>
    </div>
  );
};

export default Win;
