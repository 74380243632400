import "./Hint.scss";

const Hint = ({ hints }) => {
  if (!hints || !hints?.length) return null;
  return (
    <div className="hint">
      <div className="hint__list">
        {hints.map((h, i) => (
          <div key={h} className="hint__item">
            <span className="hint__item-order">
              {String(i + 1).padStart(2, 0)}
            </span>
            <span className="hint__item-text">{h}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Hint;
