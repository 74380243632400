import { useTranslation } from "react-i18next";
import { formatCurrency } from "utils/formatCurrency";
import { classNames } from "utils/common";
import "./Ticket.scss";

const Ticket = ({ position, amount }) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames("ticket", { [`ticket--${position}`]: !!position })}
    >
      <div className="ticket__inner">
        <span className="ticket__title">{t("win")}</span>
        <span className="ticket__amount">{formatCurrency(amount, "USD")}</span>
        <span className="ticket__description">{t("bonusBuy")}</span>
      </div>
    </div>
  );
};

export default Ticket;
