import { useCallback } from "react";

export const useSynchronousDispatch = (dispatch) => {
  const synchronousDispatch = useCallback(
    (action) => {
      return new Promise((resolve) => {
        dispatch(action);
        resolve();
      });
    },
    [dispatch]
  );
  return synchronousDispatch;
};
