import { memo } from "react";
import GameSlots from "../GameSlots";
import GameBet from "../GameBet";
import PriceSlots from "../PriceSlots/PriceSlots";
import RollSound from "../../../Sounds/RollSound";
import "./GameLayout.scss";

const GameLayout = () => {
  return (
    <div className="game-layout">
      <GameSlots />
      <div className="game-layout__price-slots-wrapper">
        <PriceSlots />
      </div>
      <div className="game-layout__game-bet-wrapper">
        <GameBet />
      </div>
      <RollSound />
    </div>
  );
};

export default memo(GameLayout);
