import { useSynchronousDispatch } from "hooks/useSynchronousDispatch";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from "react";

const initialState = {
  modals: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "openModal":
      document.body.classList.add("modal-open");
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.payload.modalType]: action.payload,
        },
      };
    case "closeModal":
      document.body.classList.remove("modal-open");
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.payload]: undefined,
        },
      };
    default: {
      return state;
    }
  }
};

const ModalContext = createContext();

const ModalProvider = ({ children }) => {
  const [{ modals }, dispatch] = useReducer(reducer, initialState);

  const modalSynchronousDispatch = useSynchronousDispatch(dispatch);

  const openModal = useCallback(
    (modal) => dispatch({ type: "openModal", payload: modal }),
    []
  );

  const closeModal = useCallback(
    (modalType) => dispatch({ type: "closeModal", payload: modalType }),
    []
  );

  const openModalSync = useCallback(
    (modal) => modalSynchronousDispatch({ type: "openModal", payload: modal }),
    [modalSynchronousDispatch]
  );

  const closeModalSync = useCallback(
    (modalType) => modalSynchronousDispatch({ type: "closeModal", payload: modalType }),
    [modalSynchronousDispatch]
  );

  const values = useMemo(
    () => ({
      modals,
      openModalSync,
      closeModalSync,
      openModal,
      closeModal,
    }),
    [modals, openModal, closeModal, openModalSync, closeModalSync]
  );

  return (
    <ModalContext.Provider value={values}>{children}</ModalContext.Provider>
  );
};

const useModal = () => useContext(ModalContext);

export { ModalProvider, useModal };
