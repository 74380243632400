const languageConfig = {
  lng: ["en", "pt"],
  fallbackLng: "en",
  fallbackNS: "common",
  lowerCaseLng: true,
  ns: "common",
  backend: {
    loadPath: "/locales/{{lng}}/{{ns}}.json",
  },
  keySeparator: ".",
};

export default languageConfig;
