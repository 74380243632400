import { useTranslation } from "react-i18next";
import PortalContainer from "components/Shared/PortalContainer";
import "./ModalTemplate.scss";

const ModalTemplate = ({ children, modalKey, descriptionParams }) => {
  const { t } = useTranslation();
  return (
    <PortalContainer
      id={`modal-template-${modalKey}`}
      className="modal-template"
    >
      <div className="modal-template__overlay" />
      <div className="modal-template__inner">
        <div className="modal-template__main">
          <div className="modal-template__header">
            <div className="modal-template__header-inner">
              <span className="modal-template__title">
                {t("congratulations")}
              </span>
              <span
                className="modal-template__description"
                dangerouslySetInnerHTML={{
                  __html: t(`${modalKey}.description`, descriptionParams || {}),
                }}
              />
            </div>
          </div>
          <div className="modal-template__body">{children}</div>
          <div className="modal-template__footer">
            <div className="modal-template__footer-inner">
              <span className="modal-template__footer-text">
                {t("footerText")}
              </span>
              <span className="modal-template__footer-link">
                {t("footerLink")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </PortalContainer>
  );
};

export default ModalTemplate;
