import { useGames } from "contexts/Game/GameContext";
import React, { useEffect, useRef } from "react";

const RollSound = () => {
  const { isRollingAllSlots, isProvidersRollingForButtonState, isBonusPricesRollingForButtonState } = useGames();

  console.log(isProvidersRollingForButtonState, isBonusPricesRollingForButtonState);
  const audioRef = useRef(null);
  
  useEffect(() => {
    if (isRollingAllSlots) {
      audioRef.current.currentTime = 1;
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [isRollingAllSlots]);

  return (
    //<audio ref={audioRef} src="/audio/roll-start.mp3" />
    <audio ref={audioRef} src="/audio/mixkit-swirling-whoosh-1493.mp3" />
  );
};

export default RollSound;
