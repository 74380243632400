import { useTranslation } from "react-i18next";
import Hint from "components/Shared/Hint";
import "./GameHint.scss";

const GameHint = () => {
  const { t } = useTranslation();
  return <div className="game-hint">
    <Hint hints={t('gameHints', { returnObjects: true })} />
  </div>;
};

export default GameHint;
