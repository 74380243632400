import { useState, useEffect } from 'react';

export const useAuthentication = () => {
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem('isAuthenticated') === 'true'
  );

  useEffect(() => {
    const checkTokenExpiration = () => {
      const tokenExpirationTime = localStorage.getItem('tokenExpiration');
      if (tokenExpirationTime && Date.now() > Number(tokenExpirationTime)) {
        logout();
      }
    };

    checkTokenExpiration();

    const intervalId = setInterval(checkTokenExpiration, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const login = (newToken, tokenExpirationSeconds) => {
    const tokenExpirationTime = Date.now() + tokenExpirationSeconds * 1000;
    localStorage.setItem('token', newToken);
    localStorage.setItem('tokenExpiration', tokenExpirationTime);
    localStorage.setItem('isAuthenticated', 'true');
    setToken(newToken);
    setIsAuthenticated(true);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('isAuthenticated');
    setToken(null);
    setIsAuthenticated(false);
  };

  return { token, isAuthenticated, login, logout };
};
