import { useEffect, useState, useRef, memo } from "react";
import { swapValues } from "utils/game";
import { classNames } from "utils/common";
import "./Slot.scss";

const isHorizontal = true;

const Slot = ({
  children,
  margin = 20,
  itemHeight = 169,
  itemWidth = 169,
  itemToShow = 3,
  winingIndex = 0,
  start,
  onFinish,
  onStart,
  styleKey,
  activeClass,
  isReseted,
  duration,
  rtl,
}) => {
  const [containerHeight, setContainerHeight] = useState(null);
  const [containerWidth, setContainerWidth] = useState(null);
  const [modifiedItems, setModifiedItems] = useState(null);
  const [winningIndex, setWinningIndex] = useState(null);
  const rootNode = useRef();
  const timer = useRef();

  useEffect(() => {
    if (isReseted && modifiedItems) {
      setWinningIndex(null);
      clearTimeout(timer.current);
      rootNode.current?.style.removeProperty(`--duration`);
      rootNode.current?.style.removeProperty(`--startPosition__${styleKey}`);
      rootNode.current?.style.removeProperty(`--endPosition__${styleKey}`);
      setContainerHeight(null);
      setContainerWidth(null);
    }
  }, [isReseted, modifiedItems, styleKey]);

  useEffect(() => {
    if (children?.length) {
      setModifiedItems(
        swapValues(children, winingIndex, (itemToShow + 1) / 2, rtl)
      );
    }
    return () => clearTimeout(timer.current);
  }, [children, winingIndex, itemToShow, rtl]);

  useEffect(() => {
    if (modifiedItems) {
      const root = document.querySelector(":root");
      root.style.setProperty(`--duration__${styleKey}`, `${duration}s`);
      if (!isHorizontal && !containerHeight) {
        root.style.setProperty(
          `--startPosition__${styleKey}`,
          `-${
            itemToShow === 1
              ? itemHeight
              : rtl
              ? (children.length - itemToShow) * itemHeight +
                (children.length - itemToShow) * margin +
                itemHeight / 2
              : itemHeight / 2
          }px`
        );
        root.style.setProperty(
          `--endPosition__${styleKey}`,
          `-${
            itemToShow === 1
              ? (children.length - 1) * itemHeight +
                (children.length - 1) * margin
              : rtl
              ? itemHeight / 2
              : (children.length - itemToShow) * itemHeight +
                (children.length - itemToShow) * margin +
                itemHeight / 2
          }px`
        );
        setContainerHeight(
          itemToShow === 1
            ? itemHeight
            : itemHeight * (itemToShow - 1) + margin * (itemToShow - 1)
        );
      }
      if (isHorizontal && !containerWidth) {
        root.style.setProperty(
          `--startPosition__${styleKey}`,
          `-${
            itemToShow === 1
              ? itemWidth
              : rtl
              ? (children.length - itemToShow) * itemWidth +
                (children.length - itemToShow) * margin +
                itemWidth / 2
              : itemWidth / 2
          }px`
        );
        root.style.setProperty(
          `--endPosition__${styleKey}`,
          `-${
            itemToShow === 1
              ? (children.length - 1) * itemWidth +
                (children.length - 1) * margin
              : rtl
              ? itemWidth / 2
              : (children.length - itemToShow) * itemWidth +
                (children.length - itemToShow) * margin +
                itemWidth / 2
          }px`
        );
        setContainerWidth(
          itemToShow === 1
            ? itemWidth
            : itemWidth * (itemToShow - 1) + margin * (itemToShow - 1)
        );
      }
      rootNode.current = root;
    }
  }, [
    containerHeight,
    containerWidth,
    modifiedItems,
    children,
    margin,
    itemHeight,
    itemWidth,
    itemToShow,
    styleKey,
    rtl,
    duration,
  ]);

  const handleAnimationEnd = () => {
    timer.current = setTimeout(() => {
      const adjustedWinningIndex = rtl
        ? modifiedItems.length -
          1 -
          (modifiedItems.length - (itemToShow + 1) / 2)
        : modifiedItems.length - (itemToShow + 1) / 2;

      setWinningIndex(adjustedWinningIndex);

      if (onFinish) {
        onFinish(winningIndex, adjustedWinningIndex);
      }
    }, 500);
  };

  const handleAnimationStart = () => {
    if (onStart) onStart();
  };

  return modifiedItems ? (
    <div className="slot">
      <div
        style={{
          overflow: "hidden",
          height: isHorizontal ? "100%" : containerHeight,
          width: isHorizontal ? containerWidth : "100%",
          alignItems: isHorizontal ? "center" : "flex-start",
          justifyContent: !isHorizontal ? "center" : "flex-start",
        }}
        className="slot__inner"
      >
        <div
          style={{
            height: isHorizontal ? "auto" : "100%",
            display: isHorizontal ? "flex" : "block",
          }}
          className={classNames("slot__list", {
            [`slot__list--horizontal-${styleKey}`]: isHorizontal,
            [`slot__list--vertical-${styleKey}`]: !isHorizontal,
            [`slot__list--horizontal-${styleKey}--start`]:
              start && isHorizontal,
            [`slot__list--vertical-${styleKey}--start`]: start && !isHorizontal,
          })}
          onAnimationEnd={handleAnimationEnd}
          onAnimationStart={handleAnimationStart}
        >
          {modifiedItems.map((item, index) => (
            <div
              key={index}
              className={classNames("slot__item", {
                [activeClass]: winningIndex !== null && winningIndex === index,
              })}
              style={{
                flexShrink: 0,
                height: itemHeight,
                width: itemWidth,
                marginTop: isHorizontal ? 0 : index === 0 ? 0 : margin,
                marginLeft: !isHorizontal ? 0 : index === 0 ? 0 : margin,
              }}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : null;
};

export default memo(Slot);
