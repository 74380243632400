import { memo } from "react";
import { formatCurrency } from "utils/formatCurrency";
import { useGames } from "contexts/Game/GameContext";
import { classNames } from "utils/common";
import "./BetInput.scss";

const BetInput = () => {
  const {
    handleIncreaseRollPrice,
    handleDecreaseRollPrice,
    rollPrice,
    isRollingAllSlots,
  } = useGames();

  return (
    <div
      className={classNames("bet-input", {
        "bet-input--disabled": isRollingAllSlots,
      })}
    >
      <div onClick={handleDecreaseRollPrice} className="bet-input__decrease">
        <span>-</span>
      </div>
      <span className="bet-input__value">
        {formatCurrency(rollPrice, "USD")}
      </span>
      <div onClick={handleIncreaseRollPrice} className="bet-input__increase">
        <span>+</span>
      </div>
    </div>
  );
};

export default memo(BetInput);
