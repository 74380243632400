import ReactDOM from "react-dom";
import { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";

const PortalContainer = ({
  children,
  id,
  selector,
  className,
  returnChildren,
}) => {
  const [container, setContainer] = useState(null);

  useEffect(() => {
    if (returnChildren) return;
    const portalContainer = document.createElement("div");
    portalContainer.id = id || "";
    if (className) {
      portalContainer.classList.add(className);
    }
    if (isMobile) {
      portalContainer.classList.add("mobile");
    }
    setContainer(portalContainer);

    const parentElement = selector
      ? document.querySelector(selector)
      : document.body;
    parentElement.appendChild(portalContainer);

    return () => {
      parentElement.removeChild(portalContainer);
    };
  }, [returnChildren, className, id, selector]);

  return returnChildren
    ? children
    : container && ReactDOM.createPortal(children, container);
};

export default PortalContainer;
