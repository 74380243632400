import { isMobile } from "react-device-detect";
import { classNames } from "utils/common";
import Game from "components/Widgets/Game";
import ModalManager from "components/Widgets/ModalManager";
import Login from "components/Widgets/Login";
import "./App.scss";
import { useAuthentication } from "hooks/useAuthentication";
import { useGames } from "contexts/Game/GameContext";
import { useCallback } from "react";

const App = () => {
  const { isAuthenticated, login, logout } = useAuthentication();
  const { handleLogoutReset } = useGames();

  const handleLogin = useCallback((token, tokenExpirationSeconds) => {
    login(token, tokenExpirationSeconds);
  }, [login]);

  const handleLogout = useCallback(() => {
    handleLogoutReset();
    logout();
  }, [handleLogoutReset, logout]);

window.alert("Lets see what will happen with Console log here");

  return (
    <div className={classNames("app", { mobile: isMobile })}>
      {isMobile ? (
        <>
          <div className="app__game-wrapper">
            {isAuthenticated ? (
              <div className="mobile-wrapper">
                <Game onLogout={handleLogout} />
              </div>
            ) : (
              <Login onLogin={handleLogin} />
            )}
          </div>
          <ModalManager />
        </>
      ) : (
        <div className="mobile-gif-wrapper">
          <img src="/images/mobile.gif" alt="" />
        </div>
      )}
    </div>
  );
};

export default App;
