import { useCallback } from "react";
import ModalTemplate from "components/Shared/ModalTemplate";
import Ticket from "components/Shared/Ticket";
import PlayBonusButton from "components/Shared/PlayBonusButton";
import { formatCurrency } from "utils/formatCurrency";
import { useGames } from "contexts/Game/GameContext";
import { useModal } from "contexts/Modal/ModalContext";
import CustomButton from "components/Shared/CustomButton";
import "./Lose.scss";

const Lose = ({ data }) => {
  const { handleProvidesRollingSync, handleResetGameSync } =
    useGames();
  const { closeModalSync } = useModal();

  const handleRoll = useCallback(async () => {
    await handleResetGameSync();
    await new Promise((resolve) => setTimeout(resolve, 500));
    await closeModalSync("lose");
    await handleProvidesRollingSync(data?.newRollPrice);
  }, [
    handleResetGameSync,
    closeModalSync,
    handleProvidesRollingSync,
    data?.newRollPrice,
  ]);

  return (
    <div className="lose">
      <ModalTemplate
        descriptionParams={{ amount: formatCurrency(data?.amount, "USD") }}
        modalKey="loseModal"
      >
        <div className="lose__inner">
          <div className="lose__ticket-thubnail-wrapper">
            <div className="lose__thubnail-wrapper">{data?.thumbnail}</div>
            <div className="lose__ticket-wrapper">
              <Ticket amount={data?.amount} position="end" />
            </div>
          </div>
          <div className="lose__buttons-wrapper">
            <div className="lose__play-bonus-button-wrapper">
              <PlayBonusButton gameId={data?.gameId} modalKeyToClose="lose" />
            </div>
            <div className="lose__custom-button-wrapper">
              <CustomButton
                onClick={handleRoll}
                textKey="loseModal"
              >
                {formatCurrency(data?.newRollPrice, "USD")}
              </CustomButton>
            </div>
          </div>
        </div>
      </ModalTemplate>
    </div>
  );
};

export default Lose;
