import { useGames } from "../../../../contexts/Game/GameContext";
import Slot from "components/Shared/Slot";
import "./GameSlots.scss";

const GameSlots = () => {
  const {
    handleSetIsProvidersRollingForButtonState,
    isProvidersRolling,
    gameSlotWinningIndex,
    games,
    isReseted,
    durationProviders,
  } = useGames();

  const handleRollingFinish = () => {
    handleSetIsProvidersRollingForButtonState(false);
  };

  return (
    <div className="game-slots">
      <div className="game-slots__arrow" />
      <div className="game-slots__slot-wrapper">
        <Slot
          margin={12}
          itemWidth={200}
          itemHeight={200}
          itemToShow={3}
          winingIndex={gameSlotWinningIndex}
          start={isProvidersRolling}
          styleKey="providers"
          activeClass="game-slots__active-slot"
          onFinish={handleRollingFinish}
          isReseted={isReseted}
          duration={durationProviders}
          rtl
        >
          {games.map(game => game.image)}
        </Slot>
      </div>
    </div>
  );
};

export default GameSlots;
