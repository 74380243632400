import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "components/Shared/Input";
import Button from "components/Shared/Button";
import "./Login.scss";
import { settings } from "configs/settings";

const Login = ({ onLogin }) => {
  const { t } = useTranslation();

  const [form, setForm] = useState({
    username: "",
    password: "",
  });

  const onChange = ({ value, name }) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (form.username.trim() && form.password.trim()) {
      axios
        .post("https://stagingapi.pokerplaza.com/api_v2/authenticatePlayer", {
          ...form,
          skinId: settings.skinId,
        })
        .then(function (response) {
          onLogin(response.data.result.token, response.data.result.exp);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  return (
    <div className="login">
      <div className="login__form-elements-wrapper">
        <form className="login__form" onSubmit={handleSubmit}>
          <div className="login__input-wrapper">
            <Input
              name="username"
              value={form.username}
              onChange={onChange}
              label={t("username")}
            />
          </div>
          <div className="login__input-wrapper">
            <Input
              name="password"
              value={form.password}
              onChange={onChange}
              type="password"
              label={t("password")}
            />
          </div>
          <div className="login__button-wrapper">
            <Button buttonStyle="primary">
              {t("login")}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
