import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Balance from "components/Shared/Balance";
import { useGames } from "contexts/Game/GameContext";
import { useModal } from "contexts/Modal/ModalContext";
import Button from "components/Shared/Button";
import "./GameHeader.scss";

const GameHeader = ({ onLogout }) => {
  const { t } = useTranslation();
  const { isShowGame, gameUrl } = useGames();
  const { openModal } = useModal();

  const handleFinish = useCallback(() => {
    openModal({ modalType: "finish" });
  }, [openModal]);

  

  return (
    <div className="game-header">
      <i className="icon-menu game-header__menu-burger" />
      {isShowGame && gameUrl && (
        <div className="game-header__finish-button-wrapper">
          <Button size="sm" onClick={handleFinish} buttonStyle="secondary">{t('finish')}</Button>
        </div>
      )}
      {!(isShowGame && gameUrl) && <div className="game-header__logout-button-wrapper">
        <Button size="sm" onClick={onLogout} buttonStyle="primary">{t('logout')}</Button>
      </div>}
      <div className="game-header__balance-widget-wrapper">
        <span>{t("balance")}</span>
        <div className="game-header__balance-wrapper">
          <Balance
            style={{
              color: "#B0D31B",
              fontSize: 12,
              letterSpacing: 0.048,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default GameHeader;
