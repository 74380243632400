import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useGames } from "contexts/Game/GameContext";
import { formatCurrency } from "utils/formatCurrency";
import ModalTemplate from "components/Shared/ModalTemplate/ModalTemplate";
import Ticket from "components/Shared/Ticket/Ticket";
import Button from "components/Shared/Button/Button";
import CustomButton from "components/Shared/CustomButton/CustomButton";
import { useModal } from "contexts/Modal/ModalContext";
import "./Finish.scss";


const Finish = () => {
  const { t } = useTranslation();
  const amount = 150 + (Math.floor(Math.random() * 100) + 1);
  const {
    handleCashoutSync,
    handleCloseGameSync,
    handleStartRollingTogetherSync,
    rollPrice,
  } = useGames();
  const { closeModalSync } = useModal();
  
  const onCashout = useCallback(
    async () => {
      await handleCashoutSync(amount);
      await closeModalSync("finish");
      await handleCloseGameSync();
    },
    [closeModalSync, handleCashoutSync, handleCloseGameSync]
    );
    
    const handleRoll = useCallback(async () => {
    await handleCashoutSync(amount);
    await closeModalSync("finish");
    await handleCloseGameSync();
    await new Promise((resolve) => setTimeout(resolve, 500));
    await handleStartRollingTogetherSync(rollPrice);
  }, [
    closeModalSync,
    handleStartRollingTogetherSync,
    rollPrice,
    handleCloseGameSync,
  ]);
  
  return (
    <div className="finish">
      <ModalTemplate modalKey="finishModal">
        <div className="finish__inner">
          <span className="finish__text">{t("bonusBuyOutCome")}</span>
          <div className="finish__ticket-wrapper">
            <Ticket amount={amount} />
          </div>
          <div className="finish__buttons-wrapper">
            <div className="finish__button-wrapper">
              <Button
                shadow
                buttonStyle="primary"
                onClick={onCashout}
              >
                {t("bankCash")}
              </Button>
            </div>
            <div className="finish__custom-button-wrapper">
              <CustomButton onClick={handleRoll} textKey="finishModal">
                {formatCurrency(rollPrice, "USD")}
              </CustomButton>
            </div>
          </div>
        </div>
      </ModalTemplate>
    </div>
  );
};

export default Finish;
