import { useTranslation } from "react-i18next";
import Button from "components/Shared/Button";
import { classNames } from "utils/common";
import "./CustomButton.scss";

const CustomButton = ({ children, onClick, textKey }) => {
  const { t } = useTranslation();
  return (
    <Button onClick={onClick} shadow buttonStyle="secondary">
      <div
        className={classNames("custom-button", {
          "custom-button--two-line": !!children,
        })}
      >
        <div className="custom-button__texts-wrapper">
          <span>{t(`${textKey}.customButton.firtsPart`)}</span>
          <span>{t(`${textKey}.customButton.secondPart`)}</span>
        </div>
        {children && <div className="custom-button__two-line">{children}</div>}
      </div>
    </Button>
  );
};

export default CustomButton;
