import { useGames } from "../../../../contexts/Game/GameContext";
import Slot from "components/Shared/Slot";
import BonusBuy from "components/Shared/BonusBuy";
import "./PriceSlots.scss";

const PriceSlots = () => {
  const {
    handleSetIsBonusPricesRolligForButtonState,
    isBonusPricesRolling,
    bonusPlayWinnigIndex,
    bonusPrices,
    isReseted,
    durationPrices,
  } = useGames();

  const handleFinish = () => {
    handleSetIsBonusPricesRolligForButtonState(false);
  };

  return (
    <div className="price-slots">
      <div className="price-slots__slot-wrapper">
        <Slot
          margin={8}
          itemWidth={170}
          itemHeight={80}
          itemToShow={3}
          winingIndex={bonusPlayWinnigIndex}
          start={isBonusPricesRolling}
          styleKey="prices"
          activeClass="price-slots__active-slot"
          onFinish={handleFinish}
          isReseted={isReseted}
          duration={durationPrices}
        >
          {bonusPrices?.map((bonus, index) => (
            <BonusBuy key={index} amount={bonus} />
          ))}
        </Slot>
      </div>
    </div>
  );
};

export default PriceSlots;
