import "./Input.scss";

const Input = ({ type = "text", label, name, value = "", onChange }) => {
  const handleOnChange = ({ target }) => {
    if (onChange) {
      onChange({ value: target.value, name: target.name });
    }
  };

  return (
    <div className="input">
      <div className="input__label-input-wrapper">
        <label htmlFor={name}>{label}</label>
        <input
          autoComplete="off"
          onChange={handleOnChange}
          value={value}
          name={name}
          id={name}
          placeholder={label}
          type={type}
        />
      </div>
    </div>
  );
};

export default Input;
