export const classNames = (...args) => {
  const classes = [];

  for (let i = 0; i < args.length; i++) {
    const arg = args[i];

    if (arg) {
      if (typeof arg === "object") {
        for (const key in arg) {
          if (arg[key]) {
            classes.push(key);
          }
        }
      } else {
        classes.push(arg);
      }
    }
  }

  return classes.join(" ").trim();
};

export const isNumber = (value) => !Number.isNaN(Number(value));