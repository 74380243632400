import { useTranslation } from "react-i18next";
import { formatCurrency } from "utils/formatCurrency";
import "./BonusBuy.scss";

const BonusBuy = ({ amount }) => {
  const { t } = useTranslation();
  return (
    <div className="bonus-buy">
      <span className="bonus-buy__price">{formatCurrency(amount, "USD")}</span>
      <span className="bonus-buy__text">{t("bonusBuy")}</span>
    </div>
  );
};

export default BonusBuy;
