export const swapValues = (array, index, dynamicIndex, rtl) => {
  if (index < 0 || index >= array.length) {
    return array.slice();
  }

  const thirdLastIndex = array.length - dynamicIndex;
  if (thirdLastIndex < 0) {
    return array.slice();
  }

  const newArray = array.slice();

  if (rtl) {
    const temp = newArray[index];
    newArray[index] = newArray[array.length - thirdLastIndex - 1];
    newArray[array.length - thirdLastIndex - 1] = temp;
  } else {
    const temp = newArray[index];
    newArray[index] = newArray[thirdLastIndex];
    newArray[thirdLastIndex] = temp;
  }

  return newArray;
};

export const generateImageArray = (ids, imagesCount) => {
  const imageArray = [];

  for (let i = 0; i < imagesCount; i++) {
    const id = ids[i % ids.length];
    const imagePath = `/images/providers/${id}.png`;

    const imageObject = {
      id,
      image: <img key={i} src={imagePath} alt="" />,
    };

    imageArray.push(imageObject);
  }

  return imageArray;
}

export const generateBonusPriceArray = (length, defaultRollPrice) => {
  return Array.from({ length }, (_, index) => {
    if (index % 2 === 0) {
      return defaultRollPrice / 2;
    } else if (index % 3 === 0) {
      return defaultRollPrice * 5;
    } else if (index % 5 === 0) {
      return defaultRollPrice * 10;
    } else {
      return defaultRollPrice * 2;
    }
  });
};

export const generateDynamicBonusPrices = (
  bonusPricesLength,
  rollPrice,
  step
) => {
  return generateBonusPriceArray(bonusPricesLength, rollPrice, step);
};
