import { useGames } from "contexts/Game/GameContext";
import "./GameIframe.scss";

const GameIframe = () => {
  const { gameUrl } = useGames();
  return (
    <div className="game-iframe">
      <iframe
        title="game-iframe"
        frameBorder={0}
        src={gameUrl}
      ></iframe>
    </div>
  );
};

export default GameIframe;
