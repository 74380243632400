import ReactDOM from "react-dom/client";
import App from "components/App";
import { ModalProvider } from "contexts/Modal/ModalContext";
import { GameProvider } from "contexts/Game/GameContext";
import "i18n";
import "styles/index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GameProvider>
    <ModalProvider>
      <App />
    </ModalProvider>
  </GameProvider>
);
