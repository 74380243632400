import { formatCurrency } from "utils/formatCurrency";
import { useGames } from "contexts/Game/GameContext";
import "./Balance.scss";

const Balance = ({ style }) => {
  const { balance } = useGames();
  return (
    <div className="balance">
      <span style={style}>{formatCurrency(balance, "USD")}</span>
    </div>
  );
};

export default Balance;
